.imageCell {
  width: 68px;
  min-width: 68px;
  height: 68px;
}

.imageCell img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.nameCell {
  width: -webkit-fill-available;
  width: -moz-available;
  min-width: fit-content;
  min-width: -moz-fit-content;
  display: flex;
  align-items: center;
}

.iban {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
  display: flex;
  align-items: center;
}

.details {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #808080;
}

.bankAccount {
  padding: 8px 15px !important;
}

.bankAccount:hover {
  background: transparent;
  cursor: initial;
}

.currencyItem:not(:first-child) {
  text-transform: uppercase;
}

.basicColumn {
  border-radius: 4px;
  border: 1px solid var(--neutralBlack7);
  height: calc(100vh - 97px);
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.basicColumn::-webkit-scrollbar {
  display: none;
}

.columnDraggedOver {
  box-shadow: 0px 32px 32px -12px #0000001F !important;
  border: 1px solid var(--deepPurple400) !important;
}

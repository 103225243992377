.modalContainer {
  width: max-content;
  min-width: 450px;
  max-width: 1430px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 45px 0px rgba(0, 0, 0, 0.25);
  max-height: 95vh;
  overflow: scroll;
  margin: 2.5vh auto;
}

.modalTitle {
  margin-bottom: 0;
}

.subTitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.015rem;
  color: #000;
}

.tableHeader {
  font-size: 12px;
  color: #808080;
  background: #fbfbfb;
  gap: 10px;
  font-weight: 400;
  border-bottom: 1px solid var(--gray-5, #e0e0e0);
}

.tableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  gap: 10px;
  width: 100%;
  min-width: 100%;
  height: auto;
  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 12px;
}

.tableRow:hover {
  background: #edf0f9;
}
.tableRow:active {
  background: #d9e0f1;
}

.positionList {
  max-height: 40vh;
  overflow: scroll;
}

.tableRowOrder,
.tableHeaderOrder {
  display: grid;
  grid-template-columns: 15px 2fr 100px 80px 71px 50px 40px 0.7fr 0.7fr 0.7fr 0.7fr 0.8fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}

.tableHeaderOrderSummary,
.tableRowOrderSummary {
  display: grid;
  grid-template-columns: 15px 2fr 50px 40px 1fr 1fr 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}

.overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.selectCustomer {
  max-width: 420px;
}

.inaccessible {
  opacity: 0.7;
}
.inaccessible:hover {
  background: inherit;
  cursor: not-allowed;
}

.stripesBgGrey {
  background-image: linear-gradient(
    45deg,
    #eeeeee 5.56%,
    #ffffff 5.56%,
    #ffffff 50%,
    #eeeeee 50%,
    #eeeeee 55.56%,
    #ffffff 55.56%,
    #ffffff 100%
  );
  background-size: 12.73px 12.73px;
}

.tableRowOrderCurrency,
.tableHeaderOrderCurrency {
  grid-template-columns: 105px 116px 50px 40px 1fr 1fr 1fr;
  width: 100%;
  gap: 8px;
  background: none;
  padding-right: 10px;
  padding-left: 10px;
  font-size: 12px;
}

.noPositionsToInvoiceBtn {
  background-color: #808080;
}

.noPositionsToInvoiceBtn:hover {
  background-color: #afaeae;
}

.amountWithoutTaxFormWrapper {
  min-height: 28px;
  margin-bottom: 0;
}

.amountWithoutTaxFormWrapperInput {
  max-height: 28px;
}

.helperText {
  font-size: 11px;
  font-style: italic;
  font-weight: 600;
  line-height: 14px; /* 120% */
  letter-spacing: normal;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 15px;
}

.buttons {
  align-items: center;
  display: flex;
  gap: 10px;
  padding: 16px 15px;
}

.tableContent {
  padding: 8px 0px;
}

.selectedInfo {
  align-items: center;
  display: flex;
  gap: 4px;
  height: 16px;
  padding: 0px 0px 4px 0px;
}

.filters {
  display: flex;
  gap: 8px;
  height: 42px;
  padding: 0px 0px 8px 0px;
}

.filtersDivider {
  height: 34px;
  border-color: var(--neutralBlack7) !important;
}

.itemsOrServices {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 28px;
}

.monthsFilter {
  height: 34px;
}

.selectableMonthTag {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.customDates {
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  padding-bottom: 12px;
}

.datePickersContainer {
  align-items: center;
  display: flex;
  gap: 16px;
  padding: 5px 0px 0px 0px;
}

.filterSelect {
  width: 130px;
}
